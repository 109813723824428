// src/components/Navbar.js
import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons
import './Navbar.css'; // Import the CSS file for Navbar component
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {!isMenuOpen ? (
        <FaBars className="menu-icon" onClick={toggleMenu} />
      ):(
        <FaTimes className="close-icon" onClick={toggleMenu} />
      )
      
      }
      
      <nav className={isMenuOpen ? "navbar open" : "navbar"}>
        <a href="#home" onClick={() => setIsMenuOpen(false)}> <Link to="/">Home</Link></a>
        <a href="#company" onClick={() => setIsMenuOpen(false)}><Link to="/Company">Company</Link></a>
        <a href="#careers" onClick={() => setIsMenuOpen(false)}>Careers</a>
        <a href="#news" onClick={() => setIsMenuOpen(false)}>News</a>
        <a href="#contact" onClick={() => setIsMenuOpen(false)}><Link to="/Contact">Contact</Link></a>
        <a href="https://webclient.myservicecircle.app" onClick={() => setIsMenuOpen(false)}>Webclient</a>
      </nav>
    </div>
  );
};

export default Navbar;
