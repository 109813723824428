// src/components/Disclaimer.js
import React from 'react';
import './Disclaimer.css'; // Import the CSS file for Disclaimer component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const Disclaimer = () => {
  return (
    <div className="disclaimer-page">
      <StickyHeader /> {/* Use the StickyHeader component */}
      <main className="disclaimer-content" style={{paddingBottom:100}}>
        <h1>Disclaimer</h1>
        <p>Welcome to MyServiceCircle.App. By using our services, you agree to the following terms and conditions. Please read them carefully.</p>
        <h4>1. General Information</h4>
        <p>MyServiceCircle.App is a platform designed to connect clients with service providers for various on-site services. While we strive to facilitate seamless interactions and transactions, MyServiceCircle.App does not directly provide these services. All services are offered by third-party providers.</p>
        <h4>2. No Endorsement</h4>
        <p>The inclusion of any service provider or client on MyServiceCircle.App does not imply endorsement or recommendation. We do not guarantee the quality, suitability, safety, or ability of any service providers. Any disputes or issues regarding the services provided must be resolved between the client and the service provider.</p>
        <h4>3. Limitation of Liability</h4>
        <p>MyServiceCircle.App, its affiliates, and its employees are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our platform. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
        <h4>4. Confidentiality and Intellectual Property</h4>
        <p>While we take measures to protect your data, MyServiceCircle.App cannot guarantee the absolute security of your information. By using our app, you acknowledge and agree that any information you share may be subject to unauthorized access. MyServiceCircle.App and its affiliates are not responsible for any breach of confidentiality.</p>
        <p>All content, trademarks, and intellectual property associated with MyServiceCircle.App are owned by their respective owners. Unauthorized use of any intellectual property is strictly prohibited.</p>
        <h4>5. User Responsibilities</h4>
        <p>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users must provide accurate, current, and complete information when registering and using MyServiceCircle.App. Any misuse of the platform, including but not limited to fraud, harassment, or spamming, will result in immediate termination of the user's account.</p>
        <h4>6. Third-Party Links</h4>
        <p>MyServiceCircle.App may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
        <h4>7. Changes to the Disclaimer</h4>
        <p>We reserve the right to modify this disclaimer at any time. Any changes will be posted on this page, and it is your responsibility to review this disclaimer periodically.</p>
        <h4>8. Governing Law</h4>
        <p>This disclaimer is governed by the laws of India. Any legal actions or proceedings related to or arising out of this disclaimer shall be resolved in the courts of Mumbai, Maharashtra, India.</p>
        <h4>9. Contact Us</h4>
        <p>If you have any questions or concerns about this disclaimer, please contact us at support@myservicecircle.app.</p>
        <p>By using MyServiceCircle.App, you acknowledge that you have read, understood, and agree to be bound by this disclaimer.</p>
      </main>
      <Footer />
    </div>
  );
};

export default Disclaimer;
