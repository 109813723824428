// src/components/Home.js
import React, { useEffect } from 'react';
import './Home.css'; // Import the CSS file for Home component
import Navbar from './Navbar';
import Footer from './Footer';
import logo from '../images/logo.png';
import playstores from '../images/playstores.png';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa'; // Import icons

const Home = () => {

  useEffect(() => {
    const handleScroll = () => {
      const blocks = document.querySelectorAll('.feature-block');
      blocks.forEach((block, index) => {
        const blockPosition = block.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;
        if (blockPosition < screenPosition) {
          setTimeout(() => {
            block.classList.add('active');
          }, index * 300); // Add delay between each block
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home" style={{paddingBottom:100,backgroundColor:'#DBDBDB'}}>
      <div className="section section1">
        <img src={logo} alt="Logo" className="logo" />
        <Navbar />
        <div className="background-overlay"></div>
      </div>
      <div className="section section2">
        <h2>What can you do with this App?</h2>
        <div className="feature-blocks">
          <div className="feature-block">
            <FaWrench className="feature-icon" />
            <h3>Service Providers</h3>
            <ul>
              <li><FaCheck className="check-icon" /> Manage your services efficiently</li>
              <li><FaCheck className="check-icon" /> Track service history</li>
              <li><FaCheck className="check-icon" /> Generate invoices</li>
              <li><FaCheck className="check-icon" /> Customer relationship management</li>
            </ul>
          </div>
          <div className="feature-block">
            <FaUsers className="feature-icon" />
            <h3>Service Agents</h3>
            <ul>
              <li><FaCheck className="check-icon" /> Track and complete service requests</li>
              <li><FaCheck className="check-icon" /> Real-time updates</li>
              <li><FaCheck className="check-icon" /> Manage service schedules</li>
              <li><FaCheck className="check-icon" /> Communication with clients</li>
            </ul>
          </div>
          <div className="feature-block">
            <FaHandHoldingHeart className="feature-icon" />
            <h3>Clients</h3>
            <ul>
              <li><FaCheck className="check-icon"/> Link your regular service providers</li>
              <li><FaCheck className="check-icon"/> Or find a new service provider</li>
              <li><FaCheck className="check-icon" /> Request services quickly</li>
              <li><FaCheck className="check-icon" /> Track service progress</li>
              <li><FaCheck className="check-icon" /> Provide feedback and ratings</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section section3">
        <h2 style={{color:'#fff',textAlign:'center',width:'100%'}}>Will be out soon on...</h2>
        <div style={{width:'100%',textAlign:'center'}}>
            <img src={playstores} className='storelogos'/>
        </div>
      </div>
        <Footer />
    </div>
  );
};

export default Home;
