const LoadingSpinner = () => {
    return (
        <div style={spinnerContainerStyle}>
            <div style={spinnerStyle}></div>
        </div>
    );
};

const spinnerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
};

const spinnerStyle = {
    width: '50px',
    height: '50px',
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #FCC100',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
};

// Add the animation in CSS
const css = `
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;
const style = document.createElement("style");
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);

export default LoadingSpinner;
