// src/components/RefundPolicy.js
import React from 'react';
import './RefundPolicy.css'; // Import the CSS file for RefundPolicy component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const RefundPolicy = () => {
  return (
    <div className="refund-policy-page">
      <StickyHeader />
      <main className="refund-policy-content" style={{paddingBottom:100}}>
        <h1>Refund Policy</h1>
        <p>Effective Date: [20.06.2024]</p>
        <p>At MyServiceCircle.App, we strive to provide our users with a reliable and high-quality service experience. However, we understand that there may be circumstances that require a refund. This Refund Policy outlines the conditions under which refunds will be granted.</p>
        
        <h4>1. Refund Eligibility</h4>
        <p>Refunds are only offered if our services are unavailable to the user for a period equal to their eligibility to use the subscription-based services, up to the expiration of the subscription. If you believe you are entitled to a refund, please review the following guidelines:</p>
        <ul>
          <li>Refund requests must be made within 15 days of the service disruption.</li>
        </ul>

        <h4>2. Refund Process</h4>
        <p>Users can request a refund through the following methods:</p>
        <ul>
          <li><strong>Online Help Desk:</strong> Raise a ticket by selecting the category meant for payment-related issues.</li>
          <li><strong>Email:</strong> Contact us at support@myservicecircle.app with the details of your request.</li>
        </ul>
        <p>Please provide proof of purchase and a detailed explanation of the issue when submitting your refund request.</p>

        <h4>3. Refund Method</h4>
        <p>Refunds will be processed within 15 days after the approval of your request and will be returned to the original payment method.</p>

        <h4>4. Transaction Fees</h4>
        <p>A basic transaction fee of 7% will be deducted from the refund amount.</p>

        <h4>5. Non-refundable Items</h4>
        <ul>
          <li><strong>App Purchases:</strong> All purchases made through the app are non-refundable. This includes subscription fees and any one-time purchases.</li>
          <li><strong>Subscription Plan Upgrades:</strong> If you upgrade your subscription plan, the new plan will be activated on a pro-rata basis. Downgrading a plan after an upgrade is not possible. This policy may be revised in the future.</li>
        </ul>

        <h4>6. Advertising Module</h4>
        <ul>
          <li>Once an ad campaign is purchased, no refund will be granted.</li>
          <li>All ad campaigns are subject to approval and must meet the strict criteria of our community guidelines.</li>
        </ul>

        <h4>7. Special Conditions</h4>
        <ul>
          <li>We reserve the right to deny a refund request if it does not meet the criteria outlined in this policy.</li>
          <li>This refund policy is subject to change, and any updates will be posted on this page.</li>
        </ul>

        <h4>Contact Us</h4>
        <p>If you have any questions or concerns regarding this refund policy, please contact us at support@myservicecircle.app.</p>
        
        <p>By using MyServiceCircle.App, you agree to the terms of this refund policy.</p>
      </main>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
