// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Disclaimer from './components/Disclaimer';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import TermsOfUsage from './components/TermsOfUsage';
import Contact from './components/Contact';
import Company from './components/Company';
import PasswordRecovery from './components/PasswordRecovery';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/TermsOfUsage" element={<TermsOfUsage />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Company" element={<Company />} />
        <Route path="/PasswordRecovery" element={<PasswordRecovery />} />
      </Routes>
    </Router>
  );
};

export default App;
